import React from 'react'
import { Helmet } from "react-helmet";

const SitelinksSearchBox = () => {
    const sitelinksSchema = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": "https://www.sport2000.de/",
        "potentialAction": {
            "@type": "SearchAction",
            "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://www.sport2000.de/search/?query={search_term_string}"
            },
            "query-input": "required name=search_term_string"
        }
    }

    return (
        <>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(sitelinksSchema)}</script>
            </Helmet>
        </>
    )
}

export default SitelinksSearchBox
